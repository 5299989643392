/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

function Seo({ description, lang, meta, title }) {
  const { defaultTitle, defaultDescription, author } = useSiteMetadata()
  const metaDescription = description || defaultDescription
  const metaTitle = title || defaultTitle

  return (
    <>
      <html lang={lang} />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="twitter:creater" content={author} />
      <link rel="preload" href="https://www.youtube-nocookie.com" />
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
